<template>
    <div class="field">
        <label class="label" :for="id">{{ label }}</label>
        <div class="input-wrapper">
            <!-- Multiselect Dropdown -->
            <vue-multiselect
                id="countries"
                :options="countries"
                v-model="selectedCountryPrefix"
                :allow-empty="false"
                :searchable="false"
                :disabled="disabled">
                <!-- Display selected country -->
                <template slot="singleLabel" slot-scope="props">
                    <img
                        v-if="props.option.code !== 'unknown'"
                        :src="'/svg/icons/flags/' + props.option.code + '.svg'"
                        alt="country flag">
                    <span>{{ props.option.prefix || 'Unknown' }}</span>
                </template>

                <!-- Display dropdown options -->
                <template slot="option" slot-scope="props">
                    <div class="option__desc">
                        <img
                            v-if="props.option.code !== 'unknown'"
                            :src="'/svg/icons/flags/' + props.option.code + '.svg'"
                            alt="country flag">
                        <span class="option__title">{{ props.option.prefix || 'Unknown' }}</span>
                    </div>
                </template>
            </vue-multiselect>

            <!-- Editable phone number -->
            <input
                :id="id"
                class="phone-input"
                v-model="parsedPhoneNumber"
                type="text"
                :placeholder="'123 456 789'"
                :disabled="disabled">
        </div>
        <p class="error" v-if="!phoneNumberIsValid && !!parsedPhoneNumber">{{ $t('phone_number.invalid_phone') }}</p>
    </div>
</template>
<script>
    import VueMultiselect from 'vue-multiselect';
    import { parsePhoneNumber, AsYouType, CountryCode, isValidPhoneNumber } from 'libphonenumber-js';

    export default {
        components: {
            VueMultiselect,
        },
        props: {
            id: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            value: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                selectedCountryPrefixRaw: null,
                phoneNumber: '',
                internationalizedPhoneNumber: '',
                phoneNumberIsValid: false,
                unknownCountry: {
                    prefix: '',
                    code: 'unknown', // Placeholder for unknown numbers
                },
                countries: [
                    { prefix: '+31', code: 'nl' },
                    { prefix: '+32', code: 'be' },
                    { prefix: '+33', code: 'fr' },
                    { prefix: '+34', code: 'es' },
                    { prefix: '+36', code: 'hu' },
                    { prefix: '+40', code: 'ro' },
                    { prefix: '+41', code: 'ch' },
                    { prefix: '+44', code: 'gb' },
                    { prefix: '+48', code: 'pl' },
                    { prefix: '+49', code: 'de' },
                    { prefix: '+359', code: 'bg' },
                    { prefix: '+370', code: 'li' },
                    { prefix: '+371', code: 'lv' },
                    { prefix: '+373', code: 'md' },
                ],
            };
        },
        methods: {
            formatPhoneNumber(value) {
                console.log('formatPhoneNumber', value);
                if (!value) {
                    if (this.value) {
                        this.$emit('phoneNumber', '');
                        this.$emit('input', '');
                    }
                    return;
                }
                const upperCasedCountryCode = this.selectedCountryPrefix?.code.toUpperCase() || 'UNKNOWN';
                const formatter = new AsYouType(upperCasedCountryCode);

                this.phoneNumber = formatter.input(value);

                this.$emit('phoneNumber', '');

                if (isValidPhoneNumber(this.phoneNumber, upperCasedCountryCode)) {
                    this.internationalizedPhoneNumber = parsePhoneNumber(this.phoneNumber, upperCasedCountryCode).formatInternational();
                    this.$emit('phoneNumber', this.internationalizedPhoneNumber);
                    this.$emit('input', this.internationalizedPhoneNumber);
                    this.phoneNumberIsValid = true;
                } else {
                    this.$emit('phoneNumber', this.phoneNumber);
                    this.$emit('input', this.selectedCountryPrefix?.prefix + this.stripPrefix(value));
                    this.phoneNumberIsValid = false;
                }
            },
            stripPrefix(value) {
                if (!value) return '';
                const escapedPrefix = this.selectedCountryPrefix?.prefix.replace('+', '\\+');
                const regex = new RegExp(`^${escapedPrefix}`);
                return value.replace(regex, '').trim();
            },
        },
        watch: {
            selectedCountryPrefix: {
                immediate: true,
                deep: true,
                handler() {
                    this.formatPhoneNumber(this.value);
                },
            },
                value: {
                    immediate: true,
                    handler(value) {
                        if (value) {
                            const country = this.countries.find((country) => value.startsWith(country.prefix));
                            this.selectedCountryPrefix = country || this.selectedCountryPrefixRaw || this.defaultCountry;
                            this.phoneNumber = this.stripPrefix(value);
                        }
                    },
                },
        },
        computed: {
            selectedCountryPrefix: {
                get() {
                    const country = this.countries.find((country) =>
                        this.value?.startsWith(country.prefix)
                    );
                    return this.selectedCountryPrefixRaw || country || this.unknownCountry;
                },
                set(value) {
                    this.selectedCountryPrefixRaw = value;
                },
            },
            parsedPhoneNumber: {
                get() {
                    return this.stripPrefix(this.value);
                },
                set(value) {
                    this.formatPhoneNumber(value);
                },
            },
        },
    };
</script>

<style lang="scss" scoped>
    .input-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        ::v-deep .multiselect {
            flex-grow: 0;
            width: auto;

            .multiselect__select {
                top: 5px;
            }

            .multiselect__tags {
                min-height: 52px;

                .multiselect__single {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }

            .multiselect__content-wrapper {
                border: 1px solid var(--color-grey-100);
                background-color: var(--color-white);

                .multiselect__content {
                    .multiselect__option {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        color: var(--color-black);

                        .option__desc {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                        }
                    }

                    .multiselect__option--highlight,
                    .multiselect__option--selected {
                        background-color: var(--color-grey-100);

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        .phone-input {
            flex-grow: 1;
            border: 1px solid var(--color-grey-100);
            border-radius: 4px;
            font-size: 1rem;
            color: var(--color-black);

            &:focus {
                border-color: var(--color-primary);
                outline: none;
            }
        }
    }

    .error {
        color: red;
        font-weight: bold;
    }
</style>
