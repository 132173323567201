<template>
    <div class="components">
        <template v-if="components && !components.length">
            <div class="component-row">
                <div class="add-component add-component--push-bottom"
                     v-if="!versionDisabled">
                    <span>{{ $t('component_list.add_component') }} <img src="/svg/caret-down.svg"></span>

                    <div class="options">
                        <a @click.prevent="addComponent(1)"
                           href="#">{{ $t('component_list.title_big') }}</a>
                        <a @click.prevent="addComponent(2)"
                           href="#">{{ $t('component_list.title') }}</a>
                        <a @click.prevent="addComponent(3)"
                           href="#">{{ $t('component_list.textbox') }}</a>
                        <a @click.prevent="addComponent(4)"
                           href="#">{{ $t('component_list.image') }}</a>
                        <a @click.prevent="addComponent(5)"
                           href="#">{{ $t('component_list.pdf') }}</a>
                        <a @click.prevent="addComponent(6)"
                           href="#">{{ $t('component_list.poll') }}</a>
                    </div>
                </div>
            </div>
        </template>

        <div v-if="computedComponents.length > 0">
            <draggable :disabled="versionDisabled"
                       handle=".handle"
                       v-model="computedComponents">
                <div class="component-row"
                     v-for="component of computedComponents">
                    <ab-component :component-type="component.component_type.name"
                                  :id="component.id"
                                  :key="'manual-component-' + component.id"
                                  :language="language"
                                  :translations="component.translations"
                                  :type="type"
                                  :version-disabled="versionDisabled" />

                    <div class="add-component add-component--push-bottom"
                         v-if="!versionDisabled">
                        <span>{{ $t('component_list.add_component') }} <img src="/svg/caret-down.svg"></span>

                        <div class="options">
                            <a @click.prevent="addComponent(1, component.id)"
                               href="#">{{ $t('component_list.title_big') }}</a>
                            <a @click.prevent="addComponent(2, component.id)"
                               href="#">{{ $t('component_list.title') }}</a>
                            <a @click.prevent="addComponent(3, component.id)"
                               href="#">{{ $t('component_list.textbox') }}</a>
                            <a @click.prevent="addComponent(4, component.id)"
                               href="#">{{ $t('component_list.image') }}</a>
                            <a @click.prevent="addComponent(5, component.id)"
                               href="#">{{ $t('component_list.pdf') }}</a>
                            <a @click.prevent="addComponent(6, component.id)"
                               href="#">{{ $t('component_list.poll') }}</a>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>
    </div>
</template>

<script>
    import Draggable from 'vuedraggable';
    import AbComponent from './AbComponent';

    export default {
        name: 'ComponentList',

        props: {
            versionDisabled: {
                type: undefined,
                required: false,
                default: false,
            },
            components: {
                type: undefined,
                required: true,
            },
            data: {
                type: undefined,
                required: true,
            },
            language: {
                type: undefined,
                required: true,
            },
            type: {
                type: undefined,
                required: true,
            },
        },

        components: {
            AbComponent,
            Draggable,
        },

        computed: {
            computedComponents: {
                set(components) {
                    const mappedComponents = components.map((component, index) => {
                        const componentList = this.$store.getters[`${this.type}s/components_by_${this.type}_id`];
                        const newcomponent = componentList.find(comp => comp.id === component.id);

                        newcomponent.position = index;
                        return newcomponent;
                    });
                    this.$store.dispatch(`${this.type}s/set_components`, { components: mappedComponents });
                },
                get() {
                    return this.$store.getters[`${this.type}s/components_by_${this.type}_id`];
                },
            },
        },

        methods: {
            addComponent(type, afterId) {
                const params = {
                    data_id: this.data.id,
                    componentable_type: `App\\Models\\${this.type}`,
                    component_type_id: type,
                    afterId: afterId || null,
                };
                this.$store.dispatch(`${this.type}s/store_empty_component`, params);
                this.$resetMessages();
                this.$flashMessage(this.$t('component_list.saved_success_flash'), 5000, 'succes');
                document.body.click();
            },
        },
    };
</script>
