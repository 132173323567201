<template>
    <div class="content">
        <div class="header">
            <h1>{{ $route.params.id ? $t('contact_form.change_contact') : $t('contact_form.add_contact') }}</h1>

            <div class="header-buttons">
                <div v-if="$route.params.id"
                     @click="deleteContact">
                    <div class="button red">
                        <span>{{ $t('contact_form.delete_contact') }}</span>
                    </div>
                </div>

                <div @click="save">
                    <div class="button green">
                        <span>{{
                            !$route.params.id ? $t('contact_form.add_contact') : $t('contact_form.save_changes')
                        }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel panel--push-top">
            <h2 class="title title--push-bottom">
                {{ $t('contact_form.data') }}
            </h2>

            <label for="divisions">{{ $t('contact_form.divisions') }}</label>
            <vue-multiselect
                id="divisions"
                v-model="selectedBusinessUnits"
                :close-on-select="false"
                :multiple="true"
                :options="businessUnits"
                :searchable="false"
                class="multiselect--push-bottom"
                deselect-label=""
                label="name"
                select-label=""
                selected-label=""
                track-by="id">
                <template slot="tag"
                          slot-scope="props">
                    <span v-text="props.option.name + ', '" />
                </template>
                <template slot="option"
                          slot-scope="props">
                    <div class="option__desc">
                        <span class="option__title">{{ props.option.name }}</span>
                        <img alt="check"
                             class="selected"
                             src="/svg/check.svg">
                    </div>
                </template>
                <span slot="noResult">{{ $t('contact_form.no_results') }}</span>
            </vue-multiselect>

            <label for="departments">{{ $t('contact_form.department') }}</label>
            <vue-multiselect
                id="departments"
                v-model="selectedDepartments"
                :close-on-select="false"
                :multiple="true"
                :options="departments"
                :searchable="false"
                class="multiselect--push-bottom"
                deselect-label=""
                label="name"
                select-label=""
                selected-label=""
                track-by="id">
                <template slot="tag"
                          slot-scope="props">
                    <span v-text="props.option.name + ', '" />
                </template>
                <template slot="option"
                          slot-scope="props">
                    <div class="option__desc">
                        <span class="option__title">{{ props.option.name }}</span>
                        <img alt="check"
                             class="selected"
                             src="/svg/check.svg">
                    </div>
                </template>
                <span slot="noResult">{{ $t('contact_form.no_results') }}</span>
            </vue-multiselect>

            <div class="field">
                <label class="label"
                       for="title">{{ $t('contact_form.title') }}</label>
                <input id="title"
                       v-model="title"
                       type="text">
            </div>

            <div class="field">
                <label class="label"
                       for="subTitle">{{ $t('contact_form.subtitle') }}</label>
                <input id="subTitle"
                       v-model="subTitle"
                       type="text">
            </div>

            <phone-number-input
                id="phone"
                :label="$t('contact_form.phone')"
                v-model="phone"
                @phoneNumber="value => handleIsValidPhoneNumber(value, 'landline')"
            />

            <phone-number-input
                id="mobile_phone"
                :label="$t('contact_form.mobile_phone')"
                v-model="mobilePhone"
                @phoneNumber="value => handleIsValidPhoneNumber(value, 'mobile')"
            />

            <div class="field">
                <label class="label"
                       for="email">{{ $t('contact_form.email') }}</label>
                <input id="email"
                       v-model="email"
                       type="text">
            </div>

            <div class="field">
                <label class="label"
                       for="language">{{ $t('contact_form.language') }}</label>
                <div class="language-select header">
                    <vue-multiselect
                        id="languages"
                        v-model="language"
                        v-bind="languageMultiselectOptions"
                        :allow-empty="false"
                        :options="languages"
                        :searchable="false">
                        <template slot="singleLabel"
                                  slot-scope="props">
                            <img :src="'/svg/flags/' + props.option.code + '.svg'"
                                 alt="country flag">
                            <span v-text="props.option.name" />
                        </template>
                        <template slot="option"
                                  slot-scope="props">
                            <div class="option__desc">
                                <img :src="'/svg/flags/' + props.option.code + '.svg'"
                                     alt="country flag">
                                <span class="option__title">{{ props.option.name }}</span>
                            </div>
                        </template>
                    </vue-multiselect>
                </div>
            </div>

            <div key="is_whatsapp" class="field field--checkbox">
                <input id="isWhatsapp"
                       v-model="hasWhatsapp"
                       :value="hasWhatsapp"
                       multiple
                       type="checkbox">
                <label for="isWhatsapp" />
                <label class="label" for="isWhatsapp">{{ $t('contact_form.has_whatsapp') }}</label>
            </div>

            <div class="field">
                <label class="label">{{ $t('contact_form.picture') }}</label>
                <div v-if="! photo"
                     class="image-uploader">
                    <input ref="image"
                           accept="image/*"
                           type="file"
                           @change="previewPhoto">
                    <p>{{ $t('upload_component.add_files') }}</p>
                    <span>{{ $t('upload_component.or') }}</span>
                    <a class="btn btn--blue"
                       href="#"
                       @click.prevent="$refs.image.click()">
                        {{ $t('upload_component.add_image') }}
                    </a>
                </div>

                <div v-if="photo"
                     class="image-preview">
                    <img v-if="photo.includes('base64')" :src="photo">
                    <img v-else :src="`/files/contacts/${photo}?v=${randomImageVersion}`">
                    <a href="#" @click.prevent="removePhoto">{{ $t('upload_component.delete_image') }}</a>
                </div>
            </div>

            <div id="contact_persons">
                <h2 class="title">
                    {{ $t('contact_form.contactpersons') }}
                </h2>
                <div v-for="(contactPerson) in contactPersons"
                     :key="contactPerson.uniqueIdentifier"
                     class="contact-persons">
                    <contact-person ref="contactPersons"
                                    :contact-id="getContactId()"
                                    :contact-person="contactPerson"
                                    @onDeleted="doDeleteContactPersonModal" />
                </div>
            </div>

            <a class="btn btn--blue"
               href="#"
               @click.prevent="addContactPerson">{{ $t('contact_form.add_contactperson') }}</a>
        </div>

        <portal-target name="modals">
            <!--Teleport modals here-->
        </portal-target>
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect/src/Multiselect';
    import PhoneNumberInput from '../PhoneNumberInput';
    import ContactPerson from './contact-person';
    import { BusinessUnitService } from '../../services';

    export default {
        name: 'ContactsForm',

        components: {
            PhoneNumberInput,
            ContactPerson,
            VueMultiselect,
        },

        beforeRouteEnter(to, from, next) {
            window.axios.get('/languages').then((response) => {
                const languages = response.data;
                const contactId = to.params.id;
                if (contactId) {
                    next((vm) => {
                        vm.languages = languages;
                        vm.fetchContact(contactId, languages);
                    });
                }
                next((vm) => {
                    vm.languages = languages;
                });
            });
        },

        data() {
            return {
                businessUnits: [],
                departments: [],

                languageMultiselectOptions: {
                    placeholder: this.$t('contact_form.select_language'),
                    label: 'name',
                    'track-by': 'code',
                    selectLabel: '',
                    selectedLabel: '',
                    deselectLabel: '',
                },

                languages: [],
                title: null,
                subTitle: null,
                phone: null,
                isLandLinePhoneValid: false,
                mobilePhone: null,
                isMobilePhoneValid: false,
                email: null,
                photo: null,
                language: null,
                hasWhatsapp: false,
                selectedBusinessUnits: [],
                selectedDepartments: [],
                contactPersons: [],

                randomImageVersion: Math.random().toString(36).substring(5),
            };
        },

        beforeMount() {
            this.getBusinessUnits().then(() => {
                setTimeout(() => {
                    this.showOnlyAllowedBusinessUnits();
                }, 400);
            });
            this.getDepartments();
        },

        methods: {
            getContactId() {
                return this.$route.params.id;
            },

            addContactPerson() {
                this.contactPersons.push({ name: null, image: null, uniqueIdentifier: this.uuidv4() });
            },

            getBusinessUnits() {
                return BusinessUnitService.fetchByPermission(this.$permissions.ADD_AND_EDIT_CONTACTS).then((response) => {
                    this.businessUnits = response.data;
                });
            },

            getDepartments() {
                window.axios.get('/departments').then((response) => {
                    this.departments = response.data.data;

                    const language = window.authUser.language_relation?.code;

                    if (language) {
                        this.departments = this.departments.map((item) => {
                            const translation = item.translations.find(() => item.language === language);

                            if (translation) {
                                item.name = translation.content;
                            }

                            return item;
                        });
                    }
                });
            },

            previewPhoto(event) {
                const input = event.target;

                if (input.files && input.files[0]) {
                    this.randomImageVersion = Math.random().toString(36).substring(5);
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        if (e.total > 22000000) {
                            this.$flashMessage(this.$t('upload_component.image_to_large'), 5000);
                        }

                        this.photo = e.target.result;
                    };

                    reader.readAsDataURL(input.files[0]);
                }
            },

            removePhoto() {
                this.photo = null;
            },

            save() {
                const contactPersons = [];

                if (this.$refs.contactPersons) {
                    this.$refs.contactPersons.forEach((el) => {
                        contactPersons.push({ name: el.name, photo: el.photo, id: el.id });
                    });
                }
                if (this.language == null) {
                    this.$flashMessage(this.$t('contact_form.language_required'), 5000, 'error');
                    return;
                }

                if (!this.isLandLinePhoneValid && !this.email) {
                    this.$flashMessage(this.$t('contact_form.invalid_phone'), 5000, 'error');
                    return;
                }

                if (!this.isMobilePhoneValid && !this.email) {
                    this.$flashMessage(this.$t('contact_form.invalid_mobile_phone'), 5000, 'error');
                    return;
                }

                const contactId = this.$route.params.id;

                const params = {
                    businessUnits: this.selectedBusinessUnits.map((b) => b.id),
                    departments: this.selectedDepartments.map((b) => b.id),
                    title: this.title,
                    subTitle: this.subTitle,
                    phone: this.phone,
                    email: this.email,
                    photo: this.photo,
                    mobilePhone: this.mobilePhone,
                    hasWhatsapp: this.hasWhatsapp,
                    language: this.language.code,
                    contactPersons,
                };

                if (contactId) {
                    this.update(contactId, params);
                    return;
                }

                this.store(params);
            },

            store(params) {
                window.axios.post('/contacts', params).then((response) => {
                    this.$flashMessage(this.$t('contact_form.contactperson_saved'), 5000, 'succes');
                    this.$router.push({ name: 'contacts.edit', params: { id: response.data.id } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors).map((error) => error[1]).flat().join('<br>');
                    this.$flashMessage(errors, 5000, 'error');
                });
            },

            update(contactId, params) {
                window.axios.put(`/contacts/${contactId}`, params).then(() => {
                    this.$flashMessage(this.$t('contact_form.contactperson_saved'), 5000, 'succes');
                    this.fetchContact(contactId, this.languages);
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors).map((error) => error[1]).flat().join('<br>');
                    this.$flashMessage(errors, 5000, 'error');
                });
            },

            fetchContact(id, languages) {
                window.axios.get(`/contacts/${id}`).then((response) => {
                    const contact = response.data;

                    this.title = contact.title;
                    this.subTitle = contact.sub_title;
                    this.phone = contact.phone;
                    this.email = contact.email;
                    this.photo = contact.photo;
                    this.hasWhatsapp = contact.has_whatsapp;
                    this.mobilePhone = contact.mobile_phone;
                    this.language = languages.find((l) => l.code === contact.lang);
                    this.selectedBusinessUnits = contact.business_units;
                    this.selectedDepartments = contact.departments;

                    const language = window.authUser.language_relation?.code;

                    if (language) {
                        this.selectedDepartments = this.selectedDepartments.map((item) => {
                            const translation = item.translations.find(() => item.language === language);

                            if (translation) {
                                item.name = translation.content;
                            }

                            return item;
                        });
                    }

                    this.contactPersons = contact.contact_persons.map((cp) => {
                        cp.uniqueIdentifier = this.uuidv4();
                        return cp;
                    });

                    if (this.businessUnits.length) {
                        this.showOnlyAllowedBusinessUnits();
                    }
                });
            },

            showOnlyAllowedBusinessUnits() {
                this.selectedBusinessUnits = this.selectedBusinessUnits.filter((d) => !!this.businessUnits.find((b) => b.id === d.id));
            },

            deleteContact() {
                const contactId = this.$route.params.id;

                window.axios.delete(`/contacts/${contactId}`).then(() => {
                    this.$flashMessage(this.$t('contact_form.contactperson_deleted'), 5000, 'succes');
                    this.$router.push({ name: 'contacts.index' });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors).map((error) => error[1]).flat().join('<br>');
                    this.$flashMessage(errors, 5000, 'error');
                });
            },

            doDeleteContactPersonModal(uniqueIdentifier) {
                const index = this.contactPersons.findIndex((cp) => cp.uniqueIdentifier === uniqueIdentifier);
                this.contactPersons.splice(index, 1);
            },

            uuidv4() {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
                    const r = Math.floor(Math.random() * 16);
                    const v = c === 'x' ? r : (r % 4) + 8;
                    return v.toString(16);
                });
            },
            handleIsValidPhoneNumber(value, phoneNumberType) {
                if (value && phoneNumberType === 'landline') {
                    this.isLandLinePhoneValid = true;
                }

                if (value && phoneNumberType === 'mobile') {
                    this.isMobilePhoneValid = true;
                }
            },
        },
    };
</script>
