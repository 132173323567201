<template>
  <div class="message-container">
    <message-container-item v-for="(message, key) in messages"
                            :key="`message-${key}`"
                            v-bind="message" />
  </div>
</template>

<script>
  import MessageContainerItem from './MessageContainerItem';

  export default {
    name: 'MessageContainer',
    components: { MessageContainerItem },
    computed: {
      messages() {
        return this.$root.messages;
      },
    },
  };
</script>

<style lang="scss">
  @import 'style';
</style>
